<!-- @format -->

<template>
	<layout-wide>
		<div v-if="hasPermission">
			<div>
				<sub-navbar page="Utilities" />
			</div>
			<div v-if="showVPopupSelectCustomer">customer</div>
			<div v-if="showVPopupCustomerAddress">
				<VPopup :closePopup="onClosePopup">
					<template #header>
						<div>
							{{ formNameCustomerAddress }}
						</div>
					</template>
					<template #body>
						<div>
							<EditStockItem />
						</div>
					</template>
					<template #footer>
						<div>
							{{ formNameCustomerAddress }}
						</div>
					</template>
				</VPopup>
			</div>
		</div>
	</layout-wide>
</template>
<script>
import { ref, onMounted } from '@vue/composition-api'
import usePriceListReportGeneralState from '@/_srcv2/pages/_utilities/price-list/scripts/usePriceListReportGeneralState.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'

export default {
	name: 'CustomerPriceList',
	components: {
		SubNavbar,
	},
	setup() {
		const hasPermission = ref(false)
		const {
			showVPopupCustomerAddress,
			showVPopupSelectCustomer,
			formNameCustomerAddress,
		} = usePriceListReportGeneralState()
		const setInitialState = () => {
			showVPopupCustomerAddress.value = false
			showVPopupSelectCustomer.value = true
		}
		// todo -------------------------------------------------------------------------
		const unlockEditPrice = () => {
			let pwd = prompt('Type password to create a list')
			if (pwd === '1994') {
				hasPermission.value = true
			} else
				alert(`Password does not match
	You don't have permission
	to create price list
	contact with administrator`)
		}
		onMounted(() => {
			unlockEditPrice()
			setInitialState()
		})
		return {
			hasPermission,
			showVPopupSelectCustomer,
			showVPopupCustomerAddress,
			formNameCustomerAddress,
		}
	},
}
</script>
<style lang="scss" scoped></style>
